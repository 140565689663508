import { DateTime } from 'luxon';
import { InvalidArgumentError } from './errors/InvalidArgumentError';

/** A mock value for use in storybook. */
export const MOCK_ARRIVAL_DATE = 'Monday, 4/11';

/**
 * Validate whether the JS Date object is considered valid.
 * @param date - The date object to validate.
 * @returns `true` if the date is valid.
 */
export const isValidDate = (date: Date): boolean => {
  // Logic borrowed from https://stackoverflow.com/a/1353711
  return date instanceof Date && !Number.isNaN(date as unknown as number);
};

/**
 * Accepts a date in a known string format and a string specifying the format and
 * returns a `Date` object.
 * @param dateString - A date string in a known format.
 * @param formatString - A string which denotes the format.
 * @returns A date object respective to the input date strings.
 * @see https://moment.github.io/luxon/#/parsing?id=table-of-tokens
 * @throws If the date string cannot be converted by the format string.
 * @example ```tsx
 * const date = dateFromFormattedDateString(
 *  '26-07-2022 00:00 America/Los_Angeles',
 *  'dd-LL-yyyy HH:mm z'
 * );
 * date.toUTCString(); // "Tue, 26 Jul 2022 07:00:00 GMT"
 * ```
 */
export const dateFromFormattedDateString = (
  dateString: string,
  formatString: string
): Date => {
  const errorMessage = `The date "${dateString}" for format string "${formatString}" is not valid.`;
  let date: Date;

  try {
    date = DateTime.fromFormat(dateString, formatString).toJSDate();
  } catch (e) {
    throw new InvalidArgumentError(errorMessage, {
      cause: e as Error
    });
  }

  if (!isValidDate(date)) {
    throw new InvalidArgumentError(errorMessage);
  }

  return date;
};

/**
 * Takes a date and coerces it to a specific timezone. Will NOT change
 * the time, only the timezone and return a valid date in that timezone.
 *
 * **Why can't we just use `DateTime.fromJSDate(date).setZone(timezone)`?**
 * The `setZone` method will change the time to match the timezone. This method
 * will only change the timezone and keep the time the same.
 *
 * This is useful in scenarios when handling user input around specified dates where
 * the timezone is also a configurable option. Calling new Date() automatically
 * sets the timezone to the local timezone of the user's machine.
 *
 * @example
 * ```tsx
 * const date = new Date('2022-07-26T00:00:00Z');
 * const timezone = 'America/Los_Angeles';
 * const coercedDate = coerceDateToZone(date, timezone);
 * coercedDate.toUTCString(); // "Tue, 26 Jul 2022 07:00:00 GMT"
 * ```
 * @param date - The date to coerce.
 * @param timezone - The timezone to coerce the date to.
 * @returns A new date object with the same time but in the specified timezone.
 */
export function coerceDateToZone(date: Date, timezone: string): DateTime {
  /**
   * Extract the date parts and create a new DateTime object.
   */
  const coercedDate = DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // There is a difference between JS and Luxon months
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes()
    },
    {
      zone: timezone
    }
  );

  return coercedDate;
}

export const { fromRFC2822 } = DateTime;
