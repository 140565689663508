'use client';

import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import {
  FunctionComponent,
  useCallback,
  type AnchorHTMLAttributes
} from 'react';

import { registerComponents } from '@/react/components/content/registry/builder-registry';
import { Link } from '@/react/components/core-ui/Link';
import { type IBuilderContent } from '@/services/isomorphic/ContentService';
import BuilderService from '@/services/isomorphic/integrations/BuilderService';

registerComponents();

export interface IBuilderRenderProps {
  /**
   * The content already fetched from builder.io.
   */
  content: IBuilderContent;
}

/**
 * Renders content that is specifically for builder.io. This should be preprepared and verified
 * to be in the correct shape.
 */
export const BuilderRender: FunctionComponent<IBuilderRenderProps> = ({
  content
}) => {
  builder.init(BuilderService.builderPublicKey);

  const renderLink = useCallback(
    (props: AnchorHTMLAttributes<any>) => (
      <Link variant="text" {...props}>
        {props.children}
      </Link>
    ),
    []
  );

  return (
    content.data && (
      // This keeps the footer from disturbing the CLS.
      <div id="content-wrapper">
        <BuilderComponent
          model={content.type}
          content={content.data}
          renderLink={renderLink}
        />
      </div>
    )
  );
};

export default BuilderRender;
