import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { dateFromFormattedDateString } from '@/utils/date-utils';
import CookieService from '@/services/isomorphic/CookieService';
import { Nullable } from '@/type-utils';
import axios, { AxiosInstance } from 'axios';

import Service from '../../Service';
import { EnvironmentService } from '../EnvironmentService';
import LoggerService from '../LoggerService';

/**
 * The parameters needed for starting a preview.
 */
export interface IPreviewStartParams {
  /**
   * The date to preview.
   */
  previewDate: string;
  /**
   * The page on which the preview was initiated. Will be redirected to this page.
   */
  slug: string;
}

/**
 * Provides information relating to preview. The main examples are Content Preview and NEXT Preview.
 */
class PreviewService extends Service {
  private _useBlankNavigation: Nullable<boolean>;
  // Preview date as number of milliseconds since epoch.
  private _previewDate: Nullable<number>;
  private _isPreview: boolean = false;

  /**
   * Base url for preview functionality.
   */
  private get _previewBaseUrl(): URL {
    return new URL('/api/preview', EnvironmentService.url.origin);
  }

  /**
   * The axios client for preview -- this api is itself
   * handed to content preview middleware to set .
   */
  private client: AxiosInstance;

  /**
   * Is preview currently enabled. This might change any given
   * service to use a preview mode. Content being the most obvious one.
   * @returns - A boolean representing preview mode or not.
   */
  public get isPreview(): Nullable<boolean> {
    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) return false;
    if (!this._isPreview && (typeof window !== "undefined")) {
      const previewParam = CookieService.tryGet('preview')?.value ?? 'false';
      if (previewParam === 'true') {
        this._isPreview = true;
      }
    }
    return this._isPreview;
  }

  public get useBlankNavigation(): boolean {
    return Boolean(this.isPreview && this._useBlankNavigation);
  }

  /**
   * Use a blank version of the navigation. This will speed up page load and is triggered
   * by a query string that is read by a middleware. Only works in the fragment preview.
   */
  public set useBlankNavigation(value: boolean) {
    this._useBlankNavigation = value;
  }

  /**
   * Gets the preview date as a string.
   * @returns - The preview date on the Date object.
   */
  public get previewDate(): Nullable<Date> {
    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) return null;
    if (!this._previewDate && (typeof window !== "undefined")) {
      const previewDate = Number(CookieService.tryGet('previewDate')?.value);

      if (previewDate && Number.isNaN(previewDate)) {
        this._previewDate = previewDate;
      }
    }
    return this._previewDate ? new Date(this._previewDate) : null;
  }

  /**
   * Rests the preview date. Until session service is in place
   * we will have to reset this on every request without a preview date.
   */
  public resetPreviewDate(): void {
    this._previewDate = null;
  }

  /**
   * Overload for date where date is a JS date object.
   * @param date - A valid JS Date.
   */
  public setPreviewDate(date: Date): void;
  /**
   * Overload for set date where date is ISO number of ms. 
   * @param date - number that represents ms.
   */
  public setPreviewDate(date: number): void;
  /**
   * Overload for date that takes an ambiguous string and a given format and converts it to
   * date ms.
   * @param date - A string that should correspond to a valid date (e.g. 2024-05-01)
   * @param formatString - Desired date string (e.g. dd-MM-yyyy)
   */
  public setPreviewDate(date: string, formatString: string): void;

  /**
   * This method takes a Date object or two strings.
   * @param date - A string or a Date object.
   * @param formatString - A string used to set the format of the date.
   * @throws - When formatString is passed, but date is not the correct type.
   */
  public setPreviewDate(
    date: Date | string | number,
    formatString?: string
  ): void {
    if (formatString && typeof date !== 'string') {
      throw new InvalidArgumentError(
        `setPreviewDate was passed a \`formatString\` argument, but \`date\`` +
          ` was not of type \`string\`. Instead date was"${typeof date}"`
      );
    }

    if (typeof date === 'string' && !formatString) {
      throw new InvalidArgumentError(
        `setPreviewDate was passed a \`date\` argument, but a null or empty \`formatString\` argument.`
      );
    }

    if (typeof date === 'number') {
      this._previewDate = date;
      return;
    }

    if (date instanceof Date) {
      this._previewDate = date.getTime();
      return;
    }

    if (typeof date === 'string' && formatString) {
      try {
        this._previewDate = dateFromFormattedDateString(
          date,
          formatString
        ).getTime();
      } catch (e: any) {
        LoggerService.warn(e);
        this._previewDate = null;
      }
    }
  }

  /** @inheritdoc */
  public constructor() {
    super();
    this.client = axios.create({
      baseURL: '/api/preview'
    });
  }

  /**
   * Enables the preview mode.
   */
  public enablePreview(): void {
    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) return;
    this._isPreview = true;
  }

  /**
   * Disables the preview mode.
   */
  public disablePreview(): void {
    this._isPreview = false;
  }

  /**
   * Starts the preview mode. Will redirect client to the api/preview/start endpoint
   * immediately on being called. This endpoint then returns a 304 redirect status to the page on which 
   * preview was initiated.
   * 
   * Example: 
   * 
   * PreviewService initiated on AHNU homepage (https://ahnu.co):
   *  - Redirect URL: https://ahnu.co/api/preview/start?previewDate=1773414060000&slug=https://ahnu.co
   * 
   * After hitting the start endpoint, a couple of things happen:
   * 
   * 1. Next.js enables draftMode, which allows all types of pages to be built upon request.
   * 2. An HTTP cookie _previewDate is set that allows previewDate to be set/read server side.
   * 3. An client cookie previewDate is set to allow previewDate read on the client.
   * 
   * Additionally, if any url parameters exist in the current slug, these are removed to prevent infinitely adding preview params.
   * 
   * Example:
   * 
   * PreviewService initiated on AHNU homepage with previewDate parameter (https://ahnu.co?previewDate=1231231231):
   *  - Formatted Redirect URL with params removed: https://ahnu.co/api/preview/start?previewDate=1773414060000&slug=https://ahnu.co
   */
  public async startPreview(): Promise<void> {
    // Get the current url from the environment service.
    if ((typeof window !== "undefined"))
      window.location.replace(this.getPreviewUrl());
  }

  /**
   * Stops preview mode. Will redirect to current page, but removes the relevant cookies and settings needed for preview mode.
   */
  public async stopPreview(): Promise<void> {
    const previewUrl = `${this._previewBaseUrl}/stop`;
    if ((typeof window !== "undefined")) window.location.replace(previewUrl);
  }

  /**
   * Adds preview data as query params to the URL.
   * @param slug - The URL to add the preview data to.
   * @returns - The URL with the preview data added.
   */
  public getPreviewUrl(): string {
    const url = new URL(`${this._previewBaseUrl}/start`);
    if (this._previewDate !== null && this._previewDate !== undefined) {
      url.searchParams.set('previewDate', this._previewDate.toString());
    }
    url.searchParams.set(
      'slug',
      this.removePreviewParams(EnvironmentService.url).toString()
    );
    return url.toString();
  }

  /**
   * Removes parameters related to the preview functionality from a given URL. Useful
   * when moving from one url to another during an active preview session.
   * @param url
   */
  private removePreviewParams(url: URL): URL {
    url.searchParams.delete('previewDate');
    return url;
  }
}

export default new PreviewService();
