import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import {
  block,
  uiImages,
  media,
  aspectRatio
} from '@/react/components/content/registry/data';

/**
 * Register the ParllaxBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(async () => import('./index'))), {
    name: 'ParallaxBlock',
    inputs: [block, media, aspectRatio],
    image: uiImages.cubeImage,
    noWrap: true
  });
};

export default register;
