import { Input } from '@builder.io/sdk';
import type { AspectRatio } from '@/react/components/content/template-data';
/**
 * Aspect ratio for the image.
 * See {@link AspectRatio} for the available values, these should always match.
 */
export const ratios = [
  '9:16',
  '3:4',
  '3:5',
  '1:2',
  '1:1',
  '2:1',
  '4:1',
  '4:3',
  '16:9'
];

export const aspectRatio = {
  name: 'aspectRatio',
  type: 'text',
  defaultValue: '1:1',
  enum: ratios,
  helperText:
    'Aspect ratio for the image. This gets converted to numerical value.'
} satisfies Input;
