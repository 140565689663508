import type { Nullable } from '@/type-utils';

import { BuilderContent, builder } from '@builder.io/sdk';

import { RequestConflictError } from '@/utils/errors';

import Service from '@/services/Service';
import ConfigurationService, {
  type Config
} from '@/services/isomorphic/ConfigurationService';
import LoggerService from '@/services/isomorphic/LoggerService';

import { ContentType } from '@/services/isomorphic/ContentService/ContentType';
import siteCached from '@/services/utils/siteCached';
import { exhaustiveGuard } from '@/utils/function-utils';
import { ModelTarget } from './schemas';
import type { IBuilderContentOptions } from './schemas/IBuilderContentOptions';

import BuilderServiceMock from './BuilderServiceMock';
import { PreviewService } from '../../PreviewService';

/**
 * Content service to normalize and handle the builder.io integration.
 * The BuilderService should be used to fetch content directly from
 * builder.io using the SDK.
 * It should also be used to provide utility methods that allow the ContentService
 * to interact with builder.io indirectly.
 * @see https://www.builder.io/c/docs/developers
 */
export class BuilderService extends Service {
  private isInitialized = false;

  /**
   * The builderio config.
   * @returns A `Config<'builderio'>`.
   */
  @siteCached
  private get config(): Config<'builderio'> {
    const config = ConfigurationService.getConfig('builderio');
    return config;
  }

  /**
   * The content config.
   * @returns A `Config<'content'>`.
   */
  @siteCached
  private get contentConfig(): Config<'content'> {
    const config = ConfigurationService.getConfig('content');
    return config;
  }

  /**
   * Initializes the builder service once.
   */
  public initBuilder(): void {
    if (this.isInitialized) {
      return;
    }

    builder.init(this.builderPublicKey);
    this.isInitialized = true;
  }

  /**
   * The builder public key from the configuration.
   * @returns The builder public key.
   */
  public get builderPublicKey(): string {
    const builderPublicKey =
      ConfigurationService.getConfig('builderio').getSetting(
        'builderPublicKey'
      ).value;

    return builderPublicKey;
  }

  /** Initializes the content service. */
  public constructor() {
    super();
  }

  /**
   * Returns the builder {@link ModelTarget} for a given {@link ContentType}.
   * @param contentType - The content type from the {@link ContentType} enum.
   * @returns A {@link ModelTarget} for the given contentType.
   * @throws An error if the contentType is not found.
   */
  public getBuilderContentType(contentType: ContentType): ModelTarget {
    switch (contentType) {
      case ContentType.Page:
        return ModelTarget.Page;
      case ContentType.Fragment:
        return ModelTarget.Section;
      default:
        return exhaustiveGuard(
          contentType,
          `No Builder.io content type found for ${contentType}.`
        );
    }
  }

  /**
   * Fetches content from builder based on a specified date on the PreviewService.
   * Will retrieve content that at least has a start date before or equal to the specified 
   * start date on the preview service, and an enddate that occurs after the specified date.
   * This inclusive behavior allows the service to fallback when no content matches the specified date.
   * @param options - Id and type of content to retrieve.
   */
  public async getPreviewContent(
    options: IBuilderContentOptions
  ): Promise<Nullable<BuilderContent>> {
    const { id, type } = options;
    const date = PreviewService.previewDate;
    // convert time to milliseconds
    const previewDate = date?.getTime() ?? new Date().getTime();
    try {
      const builderContent = await builder
        .get(type, {
          enrich: true,
          sort: {
            startDate: -1
          },
          options: {
            locale: 'Default',
            noTargeting: true,
            cachebust: true
          },
          query: {
            startDate: {
              $lte: previewDate
            },

            endDate: {
              $or: [
                {
                  $gte: previewDate
                },
                {
                  $exists: false
                }
              ]
            },
            query: {
              $elemMatch: {
                $and: [
                  {
                    property: `${type === ModelTarget.Page ? 'urlPath' : 'id'}`,
                    value: id
                  }
                ]
              }
            }
          }
        })
        .toPromise();

      return (builderContent as BuilderContent) ?? null;
    } catch (error) {
      LoggerService.error(
        new RequestConflictError('Error fetching builder content', {
          cause: error as Error
        })
      );
    }
    return null;
  }

  /**
   * Gets content when given a set of options including a tag, a urlPath, and the model name.
   * @param options - Options object for getting content from builder.io.
   * @returns The content for the page, this content is given to the builder.io SDK
   * in order to be displayed on the page. If it doesn't find anything, it returns null.
   */
  public async getContentByModel(
    options: IBuilderContentOptions
  ): Promise<Nullable<BuilderContent>> {
    const isEnabled = this.config.getSetting('enabled').value;
    if (!isEnabled) {
      LoggerService.warn(
        `Cannot get content from builder.io as it is not enabled.`
      );
      return null;
    }

    this.initBuilder();

    const cacheSeconds =
      this.contentConfig.getSetting('dynamicRevalidate').value;

    // If the content is a page then the id is the urlPath, otherwise then it is representative of the
    // id custom target.
    if (PreviewService.isPreview) {
      const previewContent = await this.getPreviewContent(options);
      return previewContent;
    }
    try {
      const builderContent = await builder
        .get(options.type, {
          userAttributes: {
            [options.type === ModelTarget.Page ? 'urlPath' : 'id']: options.id,
            tag: options.tag ?? undefined
          },
          options: {
            locale: 'Default',
            cacheSeconds
          }
        })
        .toPromise();

      return (builderContent as BuilderContent) ?? null;
    } catch (error) {
      LoggerService.error(
        new RequestConflictError('Error fetching builder content', {
          cause: error as Error
        })
      );
    }

    return null;
  }
}

export default BuilderService.withMock(
  new BuilderServiceMock(BuilderService)
) as unknown as BuilderService;
